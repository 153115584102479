var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "d-flex" },
    [
      !_vm.hasAnswered
        ? _c(
            "v-card",
            {
              staticClass: "mx-auto",
              staticStyle: { "margin-top": "36px" },
              attrs: { "max-width": "400" }
            },
            [
              _c("v-card-title", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      "Are you sure you would like to unsubscribe from receiving " +
                        _vm.$route.query.frequency +
                        " emails for " +
                        decodeURIComponent(_vm.$route.query.account) +
                        "'s account?"
                    ) +
                    "\n    "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("\n        No\n      ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { staticClass: "primary", on: { click: _vm.unsubscribe } },
                    [_vm._v("\n        Yes\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", [
            _vm.successfulResponse
              ? _c("div", { staticClass: "margin" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        "You (" +
                          decodeURIComponent(_vm.recipient) +
                          ") have been unsubscribed from having " +
                          _vm.frequency +
                          " screenshot emails sent from Truple account " +
                          decodeURIComponent(_vm.accountId)
                      ) +
                      "\n    "
                  )
                ])
              : _c("div", [
                  _c("span", [
                    _vm._v(
                      "Issue unsubscribing your email on that account, please try again later."
                    )
                  ])
                ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }